import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/descargas',
    name: 'Downloads',
    component: () => import(/* webpackChunkName: "descargas" */ '../views/Downloads.vue')
  },
  {
    path: '/documentacion',
    name: 'Docs',
    component: () => import(/* webpackChunkName: "documentacion" */ '../views/Docs.vue')
  },
  {
    path: '/documentacion/lenguaje',
    name: 'Lenguaje',
    component: () => import(/* webpackChunkName: "documentacion/lenguaje" */ '../views/Lenguaje.vue')
  },
  {
    path: '/documentacion/ejemplos',
    name: 'Examples',
    component: () => import(/* webpackChunkName: "documentacion/ejemplos" */ '../views/Examples.vue')
  },
  {
    path: '/acerca-de',
    name: 'About',
    component: () => import(/* webpackChunkName: "acerca-de" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
