<template>
  <v-treeview
    v-model="tree"
    :open="initiallyOpen"
    :items="items"
    class="text-left body-2"
    color="#145365"
    item-key="name"
    open-on-click
    activatable
    shaped
    dense
  >
    <template slot="label" slot-scope="{ item }">
      <div class="py-2" :title="item.name" @click="goTo({ to: item.to, p: item.p })">
        {{ item.name }}
      </div>
    </template>
  </v-treeview>
</template>

<script>
export default {
  data: () => ({
    tree: [],
    items: [
      {
        name: 'Empezar',
        children: [
          {
            name: 'Introducción',
            children: [
              { name: '¿Qué es Rosy IDE?', to: '#que-es-rosy-ide', p: '' },
              { name: '¿Por qué utilizar Rosy IDE?', to: '#por-que-utilizar-rosy-ide', p: '' },
              { name: 'El lenguaje de Rosy IDE', to: '#el-lenguaje-de-rosy-ide', p: '' },
            ],
          },
          {
            name: 'Instalación',
            children: [
              { name: 'Windows', to: '#windows', p: '' },
              { name: 'Debian / Ubuntu', to: '#debian-ubuntu', p: '' },
            ],
          },
          {
            name: '¿Listo para más?', to: '#listo-para-mas', p: ''
          },
        ],
      },
      {
        name: 'Lenguaje',
        children: [
          {
            name: 'Primeros pasos',
            children: [
              { name: 'Buenas prácticas', to: '#buenas-practicas', p: '/lenguaje' },
            ],
          },
          {
            name: 'Pini',
            children: [
              { name: 'Configurar pines', to: '#configurar-pines', p: '/lenguaje' },
              { name: 'Sensores y actuadores', to: '#sensores-y-actuadores', p: '/lenguaje' },
            ],
          },
          {
            name: 'Rosy',
            children: [
              { name: 'Tipos de datos', to: '#tipos-de-datos', p: '/lenguaje' },
              { name: 'Variables', to: '#variables', p: '/lenguaje' },
              { name: 'Ciclos', to: '#ciclos', p: '/lenguaje' },
              { name: 'Funciones y métodos', to: '#funciones-y-metodos', p: '/lenguaje' },
              { name: 'Estructura del lenguaje', to: '#estructura-del-lenguaje', p: '/lenguaje' },
              { name: 'Funciones generales', to: '#funciones-generales', p: '/lenguaje' },
              { name: 'Funciones de Arduino', to: '#funciones-de-arduino', p: '/lenguaje' },
              { name: 'Funciones específicas', to: '#funciones-especificas', p: '/lenguaje' }
            ],
          },
        ],
      },
      {
        name: 'Ejemplos',
        children: [
          { name: 'Blink', to: '#blink', p: '/ejemplos' },
          { name: 'Write', to: '#write', p: '/ejemplos' },
          { name: 'Read', to: '#read', p: '/ejemplos' },
          { name: 'Tone / No tone', to: '#tone-no-tone', p: '/ejemplos' },
          { name: 'Pulse', to: '#pulse', p: '/ejemplos' },
          { name: 'Sensor ultrasónico', to: '#sensor-ultrasonico', p: '/ejemplos' },
          { name: 'Módulo joystick', to: '#modulo-joystick', p: '/ejemplos' },
          { name: 'Servomotor', to: '#servomotor', p: '/ejemplos' },
          { name: 'Módulo display 4 dígitos', to: '#modulo-display-4-digitos', p: '/ejemplos' },
          { name: 'Matriz LED 8x8', to: '#matriz-led-8x8', p: '/ejemplos' }
        ],
      },
    ],
  }),
  props: {
    initiallyOpen: Array,
    dibujo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    drawer: {
      get () {
        return this.dibujo
      },
      set (value) {
        this.$emit('update:dibujo', value)
      }
    }
  },
  methods: {
    goTo (payload) {
      if (payload.to) {
        const doc = this.initiallyOpen[0]
        const rt = this.$route.name
        const p = payload.p
        if ((doc === 'Empezar' && rt === 'Docs' && p === '') || (doc === 'Lenguaje' && rt === 'Lenguaje' && p === '/lenguaje') || (doc === 'Ejemplos' && rt === 'Examples' && p === '/ejemplos')) {
          this.$vuetify.goTo(payload.to)
          if (this.$vuetify.breakpoint.smAndDown) this.drawer = false
        }
        else {
          this.$router.push(`/documentacion${p}${payload.to}`)
        }
      }
    }
  },
}
</script>