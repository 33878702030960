import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    winpus: 'https://firebasestorage.googleapis.com/v0/b/rosy-ide.appspot.com/o/rosy-ide-1.0.zip?alt=media&token=e15678f9-9b19-41f7-8665-e3f886fe6a41',
    linpus: 'https://firebasestorage.googleapis.com/v0/b/rosy-ide.appspot.com/o/rosy-ide-1.0.tar.gz?alt=media&token=f7ff97e1-5f9a-4a41-aaf1-99c818c1d780'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
