<template>
  <div>
    <v-img
      :height="$vuetify.breakpoint.xl ? '60vh' : '65vh'"
      src="../assets/banner.webp"
    >
      <v-row
        class="d-flex justify-center align-center mx-1"
        style="height:100%"
      >
        <v-col cols="12" sm="10" xl="8">
          <v-row>
            <v-col cols="12" md="8">
              <v-row
                class="d-flex text-left"
                :class="$vuetify.breakpoint.xs ? '' : 'mt-10'"
              >
                <v-col
                  cols="12"
                  class="d-flex"
                  :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'"
                >
                  <div
                    class="d-flex"
                    style="text-shadow: 1px 1px 2px #ffffff; line-height:80px"
                  >
                    <h1 style="font-family: 'Times New Roman', Times, serif; color: #d41532">Ro</h1>
                    <h1 style="font-family: 'Times New Roman', Times, serif; color: #4c7b00">sy</h1>
                    <h1 style="font-family: 'Times New Roman', Times, serif;" class="ml-6">IDE
                      <template v-if="!$vuetify.breakpoint.smAndDown">1.0</template>
                    </h1>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="white--text ml-1"
                  :class="$vuetify.breakpoint.xs ? 'text-h5' : 'display-1 mt-xl-6 mt-4'"
                  style="text-shadow: 1px 1px 4px #000000"
                >
                  Un entorno de desarrollo integrado para proyectos Arduino
                </v-col>
                <v-col
                  cols="12"
                  :class="$vuetify.breakpoint.xs ? '' : 'mt-xl-10 mt-8'"
                >
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      color="success"
                      class="mr-4"
                      dark
                      :class="$vuetify.breakpoint.xs ? 'my-4 mr-16' : ''"
                      :large="$vuetify.breakpoint.xl ? true : false"
                      @click="checkSO"
                    >
                      Descargar
                      <v-slide-x-transition>
                        <v-icon v-if="hover" right>fas fa-download</v-icon>
                      </v-slide-x-transition>
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="hover ? 'red' : 'white'"
                      :dark="hover ? true : false"
                      :large="$vuetify.breakpoint.xl ? true : false"
                      to="/documentacion"
                    >
                      Ver Documentación
                      <v-slide-x-transition>
                        <v-icon v-if="hover" right>fas fa-book</v-icon>
                      </v-slide-x-transition>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="4"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              <v-img src="../assets/logo.webp"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-img>
    <v-row class="d-flex justify-center mx-1 my-8">
      <v-col cols="12" sm="10" xl="8">
        <v-row>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-row no-gutters class="d-flex justify-center">
              <v-col cols="12">
                <h1>¿Qué es Rosy IDE?</h1>
              </v-col>
              <v-col cols="4">
                <v-divider class="my-2"></v-divider>
                <v-divider class="mb-4"></v-divider>
              </v-col>
              <v-col cols="12" class="px-4">
                Rosy IDE es una plataforma de software libre que tiene por objetivo
                facilitar y simplificar el desarrollo de proyectos que utilicen Arduino,
                mediante el uso y la implementación de un lenguaje de programación propio,
                una interfaz gráfica moderna y un potente compilador.
              </v-col>
              <v-col cols="12" class="mt-4">
                <span class="body-1 text-decoration-underline">El nombre Rosy proviene del juego de palabras</span>:
                <span class="font-italic">
                  <span class="red--text font-weight-bold">Ro</span>bot
                  ea<span class="success--text font-weight-bold">sy</span>
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="ma-md-6">
              <v-img src="../assets/img1.webp"></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="py-6">
      <v-parallax :height="$vuetify.breakpoint.xl ? '400' : '300'" src="../assets/img2.webp" style="text-shadow: 1px 1px 4px #000000">
        <span
          class="font-weight-medium mb-4"
          :class="$vuetify.breakpoint.smAndDown ? 'display-2' : 'display-3'"
        >Crea, diseña, imagina</span>
        <span
          style="font-family: 'Times New Roman', Times, serif"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size: 30px' : 'font-size: 36px'"
        >Con Rosy IDE es posible</span>
      </v-parallax>
    </div>
    <v-row class="d-flex justify-center mx-1 my-6">
      <v-col cols="12" sm="10" xl="8">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters class="d-flex justify-center">
              <v-col cols="12">
                <h1>¿Por qué utilizar Rosy IDE?</h1>
              </v-col>
              <v-col cols="3">
                <v-divider class="my-2"></v-divider>
                <v-divider class="mb-4"></v-divider>
              </v-col>
            </v-row>
          </v-col>

          <!-- imagen 1 -->
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="12" md="4" class="d-flex align-center" style="heigt:100%">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-hover v-slot="{ hover }">
                      <v-avatar
                        style="box-shadow: 1px 1px 4px #000000"
                        size="125"
                        :color="hover ? 'indigo' : ''"
                        @click="showSheet(text_img1)"
                      >
                        <v-icon :color="hover ? 'white' : 'indigo'" x-large v-text="hover ? 'fas fa-chalkboard' : 'fas fa-chalkboard-teacher'"></v-icon>
                      </v-avatar>
                    </v-hover>
                  </v-col>
                  <v-col cols="12">
                    <div
                      class="display-1 font-weight-medium mt-4"
                      :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mx-8 mb-8'"
                    >Perfecto para aprender</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="8">
                <v-hover v-slot="{ hover }">
                  <v-card>
                    <v-img max-height="400" src="../assets/img3.webp">
                      <v-expand-transition>
                        <v-overlay
                          absolute
                          v-if="hover"
                          class="d-flex text-h5 white--text px-md-16 px-2"
                          style="height: 100%; text-shadow: 1px 1px 4px #000000"
                        >
                          {{ text_img1 }}
                        </v-overlay>
                      </v-expand-transition>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>

          <!-- imagen 2 -->
          <v-col cols="12" class="my-md-8 my-2">
            <v-row no-gutters>
              <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="8">
                <v-hover v-slot="{ hover }">
                  <v-card>
                    <v-img max-height="400" src="../assets/img4.webp">
                      <v-expand-transition>
                        <v-overlay
                          absolute
                          v-if="hover"
                          class="d-flex text-h5 white--text px-md-16 px-2"
                          style="height: 100%; text-shadow: 1px 1px 4px #000000"
                        >
                          {{ text_img2 }}
                        </v-overlay>
                      </v-expand-transition>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" md="4" class="d-flex align-center" style="heigt:100%">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-hover v-slot="{ hover }">
                      <v-avatar
                        style="box-shadow: 1px 1px 4px #000000"
                        size="125"
                        :color="hover ? 'red' : ''"
                        @click="showSheet(text_img2)"
                      >
                        <v-icon :color="hover ? 'white' : 'red'" x-large v-text="hover ? 'fas fa-lightbulb' : 'far fa-lightbulb'"></v-icon>
                      </v-avatar>
                    </v-hover>
                  </v-col>
                  <v-col cols="12">
                    <div
                      class="display-1 font-weight-medium mt-4"
                      :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mx-8 mb-8'"
                    >Un robot, distintas lógicas</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <!-- imagen 3 -->
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="12" md="4" class="d-flex align-center" style="heigt:100%">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-hover v-slot="{ hover }">
                      <v-avatar
                        style="box-shadow: 1px 1px 4px #000000"
                        size="125"
                        :color="hover ? 'success' : ''"
                        @click="showSheet(text_img3)"
                      >
                        <v-icon :color="hover ? 'white' : 'success'" x-large v-text="hover ? 'fas fa-laptop-code' : 'fas fa-laptop'"></v-icon>
                      </v-avatar>
                    </v-hover>
                  </v-col>
                  <v-col cols="12">
                    <div
                      class="display-1 font-weight-medium mt-4"
                      :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mx-8 mb-8'"
                    >Interfaz gráfica intuitiva</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="8">
                <v-hover v-slot="{ hover }">
                  <v-card>
                    <v-img max-height="400" src="../assets/img5.webp">
                      <v-expand-transition>
                        <v-overlay
                          absolute
                          v-if="hover"
                          class="d-flex text-h5 white--text px-md-16 px-2"
                          style="height: 100%; text-shadow: 1px 1px 4px #000000"
                        >
                          {{ text_img3 }}
                        </v-overlay>
                      </v-expand-transition>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-col>
    </v-row>

    <v-bottom-sheet v-if="$vuetify.breakpoint.smAndDown" v-model="sheet">
      <v-sheet class="text-center pa-8">
        {{ text }}
      </v-sheet>
    </v-bottom-sheet>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',
  title: 'Inicio',
  data: () => ({
    sheet: false,
    text: '',
    text_img1: 'Rosy IDE cuenta con un innovador lenguaje de programación, diseñado coloquialmente con el fin de evitar el uso excesivo de símbolos y gramáticas que generen confusión en programadores inexpertos.',
    text_img2: 'La implementación del sub-lenguaje Pini permitie almacenar una sola configuración de pines para un mismo robot que ejecuta lógicas distintas. De esta manera, se logra reducir el código y evitar la redundancia.',
    text_img3: 'El editor de Rosy IDE posee imágenes a escala de los sensores y actuadores más utilizados para Arduino, lo cual ayuda el armado y facilita la configuración de la placa.',
    items: [
      { title: '', text: '', img: '../assets/img3.webp' },
      { title: '', text: '', img: '../assets/img4.webp' },
      { title: '', text: '', img: '../assets/img5.webp' },
    ],
  }),
  computed: {
    ...mapState(['winpus', 'linpus'])
  },
  methods: {
    showSheet(text) {
      this.sheet = !this.sheet
      this.text = text
    },
    checkSO () {
      const so = window.navigator.appVersion.toLowerCase()
      if(so.indexOf('win') != -1) {
        window.open(this.winpus, '_parent')
      } else if(so.indexOf('linux') != -1) {
        window.open(this.linpus, '_parent')
      } else {
        this.$router.push('/descargas')
      }
    }
  }
}
</script>
