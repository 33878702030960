<template>
  <v-app>
    <v-app-bar
      app
      dark
      dense
      color="red"
      elevate-on-scroll
    >
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="10" xl="8" class="d-flex justify-center align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.smAndDown && ($route.name === 'Docs' || $route.name === 'Lenguaje' || $route.name === 'Examples')"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <v-toolbar-title style="font-family: 'Times New Roman', Times, serif; cursor: default"><h2>Rosy IDE</h2></v-toolbar-title>
          <v-spacer></v-spacer>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-app-bar-nav-icon @click="sheet = !sheet"></v-app-bar-nav-icon>
            <v-bottom-sheet v-model="sheet">
              <v-sheet class="text-center pa-6">
                <div v-for="({ title, to }, index) in items" :key="index">
                  <v-btn
                    class="body-1 font-weight-medium red--text"
                    style="text-transform: none"
                    text
                    block
                    x-large
                    :to="to"
                    @click="sheet = !sheet"
                  >{{ title }}</v-btn>
                </div>
              </v-sheet>
            </v-bottom-sheet>
          </template>
          <template v-else>
            <div v-for="({ title, to }, index) in items" :key="index">
              <v-hover v-slot="{ hover }">
                <router-link
                  :style="hover ? '' : 'text-decoration: none'"
                  class="body-1 white--text font-weight-medium ml-6"
                  :to="to"
                >{{ title }}</router-link>
              </v-hover>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      class="pr-2"
      app
    >
      <Index v-if="$route.name === 'Docs'" :initiallyOpen="['Empezar', 'Introducción', 'Instalación']" :dibujo.sync="drawer" />
      <Index v-else-if="$route.name === 'Lenguaje'" :initiallyOpen="['Lenguaje', 'Primeros pasos', 'Pini', 'Rosy', 'Configurar pines']" :dibujo.sync="drawer" />
      <Index v-else-if="$route.name === 'Examples'" :initiallyOpen="['Ejemplos']" :dibujo.sync="drawer" />
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer
      dark
      padless
      class="mt-8"
    >
      <v-card
        class="flex"
        flat
        tile
      >
        <v-card-text class="white--text body-2" style="background-color: #145365">
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="10" xl="8">
              <v-row>
                <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="2">
                  <v-img class="ma-6" src="./assets/rosy.webp"></v-img>
                </v-col>
                <v-col cols="12" md="10">
                  <v-row style="height:100%">
                    <v-col
                      cols="6" md="4"
                      class="d-flex align-center"
                      :class="$vuetify.breakpoint.smAndDown ? 'justify-end' : ''"
                      style="font-family: 'Times New Roman', Times, serif; font-size: 36px; line-height: 50px"
                    >
                      Rosy IDE
                    </v-col>
                    <v-col
                      cols="6" md="8"
                      :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center justify-end'"
                      class="d-flex"
                    >
                      <div v-for="({ title, to }, index) in items" :key="index">
                        <v-hover v-slot="{ hover }">
                          <router-link
                            :style="hover ? '' : 'text-decoration: none'"
                            class="body-1 white--text font-weight-medium ml-6"
                            :to="to"
                          >{{ title }}</router-link>
                        </v-hover>
                      </div>
                      <v-hover v-slot="{ hover }">
                        <a
                          :style="hover ? '' : 'text-decoration: none'"
                          class="body-1 white--text font-weight-medium ml-6"
                          href="https://github.com/martins36/Rosy-IDE"
                          target="_blank"
                        >Código fuente</a>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-footer>

    <v-slide-y-reverse-transition>
      <v-btn
        v-show="scrollPosition > 100 ? true : false"
        color="red"
        bottom
        right
        fixed
        dark
        fab
        small
        @click="$vuetify.goTo(0)"
      ><v-icon small>fas fa-chevron-up</v-icon></v-btn>
    </v-slide-y-reverse-transition>

  </v-app>
</template>

<script>
import Index from './components/Index'

export default {
  data: () => ({
    scrollPosition: null,
    sheet: false,
    drawer: false,
    items: [
      { title: 'Inicio', to: '/' },
      { title: 'Descargas', to: '/descargas' },
      { title: 'Documentación', to: '/documentacion' },
      { title: 'Acerca de', to: '/acerca-de' },
    ],
  }),
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  components: {
    Index
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
  }
}
</script>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.index {
  text-decoration: none;
  color: #2c3e50 !important;
}
</style>
